/* Gogle fonts Poppins for heading style */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Noto+Sans+Devanagari:wght@300;400;500;600;800;900&display=swap');


@tailwind base;
@tailwind components;

@tailwind utilities;


@layer utilities {

    /* Define a new utility class */
    .container {
        max-width: 1280px;
    }

    .btn {
        background-color: #009933;
        margin-top: 30px;
        padding: .6rem 1rem;
        color: white;
        border: 2px solid #009933;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .btn:hover {
        background-color: #fff;
        border: 2px solid #009933;
        color: #009933;
        transition: 0.6s;
    }

}

@layer base {

    h1,
    h2 {
        font-family: 'Poppins', sans-serif;
        font-size: 44px;
        font-weight: 700;
        line-height: 1.3;
        color: #071c1f;
    }

    h3,
    h4 {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3;
        color: #071c1f;
    }

    h5,
    h6 {
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.3;
        color: #071c1f;
    }

    p {
        line-height: 1.5;
        letter-spacing: .2px;
        color: #5C727D;
        font-family: 'Poppins', sans-serif;
        font-weight: normal;
    }

    li {
        line-height: 1.5;
        letter-spacing: .2px;
        font-family: 'Poppins', sans-serif;
        font-weight: normal;
    }
}

.loadercustomization {
    position: fixed;
    /* display: none; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: progress;
}

.loadercustomizationmain {
    position: absolute;
    top: 50%;
    left: 45%;
}

.errorName {
    color: red;
}

.errorList::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 10px;
    background-color: red;
    margin: 0px 10px 0px 3px;
}

.order_content {
    border: 1px solid;
    padding: 10px;
    width: 150px;
}


.order_heading {
    border: 1px solid;
    padding: 10px;
    background: #009933;
}

.order_main_container {
    overflow: auto;
}

.error_validation {
    color: #FF0000;
    padding-bottom: 20px;
    margin-top: -20px;
    font-size: 13px;
}

/* CSS for the animated button */
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  /* background-clip: text; */
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 30px;
  font-weight: 800;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}