.container-slider {
    max-width: 400px;
}

.slick-slider {
    margin-bottom: 1rem;
}

.slick-slide>div {
    outline: none;
}

.slick-dots {
    display: flex !important;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: relative;
    top: -30px;
    list-style: none;
}

.slick-dots li button:before {
    font-size: 1rem;
    color: white;
}

.slick-dots li.slick-active button:before {
    color: green;
}

.slick-prev:before,
.slick-next:before {
    font-size: 1.5rem;
    color: #777;
}

.slick-prev {
    left: 1rem;
    z-index: 1;
}

.slick-next {
    right: 1rem;
    z-index: 1;
}